//const API_URL = "http://localhost:4000/api";
//const API_URL = "https://testforumback.onrender.com";
//const API_URL = "https://forumbackend.revnitro.com";
// const API_URL = "https://forum.revnitro.com";
// const API_URL = "https://forumbackend.revnitro.com";
const API_URL = "https://events.revnitro.com/api";

// const API_URL = `${req.protocol}://${req.get("host")}`;

export default API_URL;
